<template>
  <v-card
    color="element"
    width="407"
    height="210"
    class="rounded-lg"
    @click="next"
  >
    <v-card-text class="defaultText--text">
      <p>{{ title }}</p>
      <p class="text-h6">{{ text }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PerformanceCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
  },
};
</script>
